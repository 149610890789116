.chat-message {
  position: absolute;
  top: 1vmin;
  padding: 1rem 1.5rem;
  border-radius: 25px 25px 25px 5px;
  font-size: 1rem;
  &.agent {
    left: 1vmin;
    background-color: #424656;
  }
  &.user {
    background-color: #1D90F5;
    right: 1vmin;
    border-radius: 25px 25px 5px 25px;
  }
  &.writing {
    width: 2.5rem;
    padding: 0 0 0 1.5rem;
    top: unset;
    bottom: 1rem;
  }
}
