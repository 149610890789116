@import '../../variables';

.bottom-bar {
  position: absolute;
  width: 100%;
  height: 10%;
  bottom: 0;
  background: #F9FBFF;
  border-radius: 0 0 10px 10px;

  input {
    padding: 7px;
    width: 74%;
    left: 5%;
    position: absolute;
    border: 0;
    height: 50%;
    top: 15%;
    background: #F9FBFF;
    color: #333;
    font-family: $font;
    font-size:1rem;
    &:focus {
      outline: none;
    }
  }
  button {
    position: absolute;
    background: none;
    border: 0;
    font-size: 3vmin;
    color: #222;
    top: 25%;
    opacity: 0.8;
    right: 17px;
    cursor: pointer;
    outline: 0;
    transition: color linear 0.2s;
    &:hover {
      color:$primary;
    }
  }
}

