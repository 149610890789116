@import './variables';

body {
  display: grid;
  font-family: $font;
}

.App-header {
  background-color: #272A37;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

