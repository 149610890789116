.chat-box {
  display:none;
  opacity:0;
  position: relative;
  border-radius: 25px;
  width: 485px;
  height: 800px;
  padding-top: 0.5rem;
  background: #323644;
  background-size: 400% 400%;
  transition: opacity 2000ms;

  &.entering {
    display: block;
  }

  &.entered {
    transition: opacity 2000ms;
    opacity: 1;
    display: block;
  }

  .chat-box-content {
    position: relative;
    width: 100%;
    height: 90%;
    overflow-y: auto;
  }
}

@keyframes gradient-background-chat-box {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
