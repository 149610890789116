
.App-logo {
  height: 40vmin;
  pointer-events: none;
  filter: invert(81%) sepia(20%) saturate(50%) hue-rotate(
                  20deg) brightness(152%) contrast(200%);
  transition: all 6000ms;

  &.entering {
    opacity: 0;
    display: block;
  }
  &.entered {
    opacity: 1;
    display: block;
  }
  &.exiting {
    opacity: 1;
    display: block;
    filter: invert(81%) sepia(50%) saturate(90%) hue-rotate(
                    50deg) brightness(152%) contrast(200%);
  }
  &.exited {
    animation: App-logo-spin infinite 4s linear;
    transition: all 2000ms;
    height: 1vmin;
    opacity: 0;
    filter: invert(81%) sepia(50%) saturate(90%) hue-rotate(
                    50deg) brightness(152%) contrast(0%);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
